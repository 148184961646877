import { track } from './track';
import { AnalyticEventData, TrackWithDetailsProps } from './types';

declare const window: Window & {
  analytics?: SegmentAnalytics.AnalyticsJS;
  FS?: {
    getCurrentSessionURL: (includeCompleteURL: boolean) => string;
  };
};

const trackWithDetails = ({
  event,
  category,
  component,
  cta,
  metricValue,
  type,
  pathname,
  ...additionalAttributes
}: TrackWithDetailsProps) => {
  const attrs: AnalyticEventData = {
    component,
    type,
    cta,
    url: window.location.href,
    path: window.location.pathname,
    ...additionalAttributes,
  };

  if (window.FS !== undefined && typeof window.FS.getCurrentSessionURL === 'function') {
    attrs.fullstorySession = window.FS.getCurrentSessionURL(true);
  }

  if (window.H !== undefined && typeof window.H.track === 'function') {
    window.H.track(event, attrs);
  }

  track(event, category, attrs, metricValue);
};

export { trackWithDetails };
